/* You can add global styles to this file, and also import other style files */
// @import "variales.scss";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~nouislider/distribute/nouislider.min.css";
$primary-color: #022b5c;
$yellow-color: #E3A200;
$orange-color: #EE5700;
$green-color: #28cc64;
$levender-color: #8a45d4;
$primary-text-color: #2B3134;
$border-grey-color: #ddd;
$dark-bg-color: #F3F5FF;
$bg-color: #fafafa;
$red-color: #dc3545;

$remix-font: remixicon;

::placeholder {
  color: #d4d4d4 !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #d4d4d4 !important;
}

::-ms-input-placeholder {
  color: #d4d4d4 !important;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  background-color: $bg-color !important;
  overflow-x: hidden;

  #tidio-chat-iframe {
    bottom: 0 !important;
    left: -7px !important;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $primary-color;
  }

  .text-black {
    color: $primary-text-color;
  }

  .text-orange {
    color: $orange-color !important;
  }


  .text-yellow {
    color: $yellow-color !important;
  }

  .text-green {
    color: $green-color !important;
  }
  
  .text-red {
    color: $red-color !important;
  }

  .text-primary {
    color: $primary-color !important;
  }
  
  .text-levender {
    color: $levender-color !important;
  }

  .bg-primary {
    background-color: $primary-color !important;
  }
 
  .bg-primary-light {
    background-color: rgba($color: $primary-color, $alpha: 0.4) !important;
  }
  
  .bg-primary-dark {
    background-color: #3346c5 !important;
  }

  .bg-yellow {
    background-color: $yellow-color !important;
  }
  
  .bg-yellow-dark {
    background-color: #d8bf1f !important;
  }

  .bg-yellow-light {
    background-color: rgba($color: $yellow-color, $alpha: 0.4)!important;
  }

  .bg-orange {
    background-color: $orange-color !important;
  }
  
  .bg-green {
    background-color: $green-color !important;
  }
  
  .bg-levender {
    background-color: $levender-color !important;
  }
  
  .bg-levender-dark {
    background-color: #6c27b7 !important;
  }

  .border-color-primary{
    border-color: $primary-color!important;
  }
  .border-color-yellow{
    border-color: $yellow-color!important;
  }
  .border-color-levender{
    border-color: $levender-color!important;
  }

  .rotate-90 {
    transform: rotate(90deg);
  }

  .pointer {
    cursor: pointer;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .text-underline {
    text-decoration: underline;
  }

  [class^=ri-],
  body [class*=" ri-"] {
    display: inline-block;
  }

  [class^="ri-"],
  [class*=" ri-"] {
    vertical-align: middle;
    line-height: 1;
  }

  .alert-warning,
  .alert-success {
    font-size: 18px;
    font-weight: 600;
  }

  .border-dashed {
    border-style: dashed;
  }

  a {
    color: $primary-text-color;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  :disabled {
    cursor: not-allowed;
  }

  .no-drop {
    cursor: not-allowed;
  }

  .border-bottom-yellow {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background-color: $yellow-color;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
    }
  }

  .no-data {
    font-size: 20px;
    font-weight: 700;
    color: rgba($color: $primary-text-color, $alpha: 0.6);
    text-align: center;
    padding: 60px 30px;

    img {
      display: inline-block;
      width: 60px;
      margin-bottom: 16px;
    }

    i {
      font-size: 30px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .pointer {
    cursor: pointer;
  }

  .form-group {
    position: relative;
    label {
      font-size: 15px;
      font-weight: 400;
      color: $primary-text-color;
      opacity: 0.8;
      padding: 0;
      display: block;
      margin: 0 0 4px;
      text-transform: capitalize;
    }

    .custom-control-label {
      opacity: 1;
      margin: 0;
      cursor: pointer;
    }


    textarea.form-control {
      min-height: 36px;
      height: auto;
    }

    .custom-file-label {
      opacity: 1;
      margin: 0;
      padding: 0.375rem 4.5rem 0.375rem 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .custom-file-input:focus~.custom-file-label {
      outline: none;
      box-shadow: none;
    }

    .radio-button {
      padding: 0px;
      margin: 0 0.5rem;
      flex: 1 0 auto;

      .custom-control-label {
        background-color: #fff;
        padding: 10px 16px;
        color: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 4px;
        text-align: center;

        &::before,
        &::after {
          display: none;
        }
      }

      .custom-control-input:checked~.custom-control-label {
        background-color: $primary-color;
        color: #fff;
      }
    }

    .radio-tab-button {
      padding: 0;

      .custom-control-label {
        background-color: transparent;
        border: none;
        border-bottom: 3px solid transparent;
        color: $primary-text-color;
        border-radius: 0;
        text-align: center;
        padding: 0.4rem 1.4rem;

        &::before,
        &::after {
          display: none;
        }
      }

      .custom-control-input:checked~.custom-control-label {
        background-color: transparent;
        color: $primary-color;
        border-color: $primary-color;
      }
      #Custom:checked~.custom-control-label,
      #CustomsPackingList:checked~.custom-control-label
      {
        background-color: transparent;
        color: $yellow-color;
        border-color: $yellow-color;
      }
    }

    .country-dropdown {
      position: relative;
      min-width: 120px;
      border-bottom: 1px solid #ddd;
      line-height: 39px;

      .ng-dropdown-panel .ng-option {
        padding: 6px;
      }
      .ng-dropdown-footer:hover {
        background-color: #fff;
      }
    }

    .country-dropdown::after {
      display: none;
    }

  }

  /* .form-group */
  .data{
    pre {
      font-family: inherit;
      font-size: inherit;
      color: initial;
      white-space: pre-line;
    }
  }
  input:-internal-autofill-selected {
    background-color: #fff !important;
  }

  .btn-table-add {
    position: absolute;
    right: 16px;
    top: 2px;
  }

  .custom-control-label {
    opacity: 1;
    margin: 0;
    cursor: pointer;
  }

  .custom-control-input:focus~.custom-control-label::before {
    outline: none;
    box-shadow: none;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;
  }

  .custom-control-input:disabled~.custom-control-label,
  .custom-control-input:disabled~.custom-control-label::before,
  .custom-control-input[disabled]~.custom-control-label::before {
    background-color: #e9ecef;
    cursor: no-drop;
  }

  .form-control,
  .custom-select {
    border-radius: 0;
    border-color: $border-grey-color;
    height: 36px;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $primary-text-color;
    }
  }

  input {
    font-family: 'Nunito'sans-serif !important;
  }

  .ng-select-hidden-input {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 1rem;
    z-index: -1;
    opacity: 0;
  }

  .form-control.readonly {
    background-color: #fff;
  }

  .input-group {
    .input-group-text {
      background-color: #fff;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
    }

    button {
      height: 36px;
      line-height: 36px;
    }
  }

  .file-badge {
    background-color: $border-grey-color;
    color: rgba($color: $primary-text-color, $alpha: 0.8);
    font-size: 14px;
    padding: 0 6px;
    margin: 2px 4px;
    border-radius: 6px;
    line-height: 25px;
    vertical-align: middle;

    .close {
      float: none;
      display: inline-block;
      padding: 4px;
      margin-left: 4px;
      height: auto;
      width: auto;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .border-top-radius-30 {
    border-top-left-radius: 30px !important;
  }

  video {
    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  .btn {
    position: relative;
    border-radius: 2px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;
    line-height: 36px;
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;

      &::after {
        animation: scaling ease-in .4s;
        transform: scale(1);
      }
    }

    &::after {
      content: '';
      display: block;
      width: 500px;
      height: 500px;
      border-radius: 50%;
      background-color: #fff;
      transform: scale(0.01);
      position: absolute;
      left: calc(50% - 250px);
      bottom: calc(50% - 250px);
      opacity: 0;
    }
  }

  @keyframes scaling {
    0% {
      transform: scale(0);
      opacity: .3;
    }

    90% {
      transform: scale(1);
      opacity: .3;
    }

    100% {
      opacity: 0;
    }
  }

  .dropdown-toggle {
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      margin-left: .255em;
      vertical-align: .255em;
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
      position: absolute;
      right: 0px;
      top: 49%;
    }
  }

  .btn-primary {
    color: #fff;
    background-color: $primary-color;
    border: 1px solid $primary-color;

    &:hover,
    &:focus,
    &:disabled,
    &:active {
      outline: none;
      box-shadow: none;
      background-color: $primary-color;
      color: #fff;
    }
  }
  .swal2-confirm.swal2-styled {
    color: #fff!important;
    background-color: $primary-color!important;
    border: 1px solid $primary-color!important;

    &:hover,
    &:focus,
    &:disabled,
    &:active {
      outline: none!important;
      box-shadow: none;
      background-color: $primary-color!important;
      color: #fff!important;
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  .btn-primary-o {
    color: $primary-color;
    background-color: #ffff;
    border: 1px solid $primary-color;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      background-color: $primary-color;
      color: #fff;
    }
  }

  .btn-green {
    color: #fff;
    background-color: $green-color;
    border-color: $green-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $green-color;
      color: #fff;
    }
  }
  .btn-green-o {
    color: $green-color;
    background-color: #fff;
    border-color: $green-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $green-color;
      color: #fff;
    }
  }

  .btn-yellow {
    color: $primary-text-color;
    background-color: $yellow-color;
    border-color: $yellow-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $yellow-color;
      color: $primary-text-color;
    }
  }
 
  .btn-red {
    color: #fff;
    background-color: $red-color;
    border-color: $red-color;

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      color: $red-color;
    }
  }

  .btn-link {
    color: $primary-color;
  }

  .btn-notification{
    border-radius: 0;
    border: 0;
    background-color: #fff;
    font-size: 22px;
    img{
      width: 16px!important;
      height: auto!important;
      object-fit: none!important;
      border-radius: 0!important;
    }
    .alarm{
      animation: shake 0.9s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
      animation-iteration-count: 3;
      -webkit-animation-iteration-count: 3;
      -moz-animation-iteration-count: 3;
    }
    .bell{
      -webkit-animation: ring 4s .7s ease-in-out 3;
      -webkit-transform-origin: 50% 4px;
      -moz-animation: ring 4s .7s ease-in-out 3;
      -moz-transform-origin: 50% 4px;
      animation: ring 4s .7s ease-in-out 3;
      transform-origin: 50% 4px;
    }
  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotateZ(0);
    }

    1% {
      -webkit-transform: rotateZ(30deg);
    }

    3% {
      -webkit-transform: rotateZ(-28deg);
    }

    5% {
      -webkit-transform: rotateZ(34deg);
    }

    7% {
      -webkit-transform: rotateZ(-32deg);
    }

    9% {
      -webkit-transform: rotateZ(30deg);
    }

    11% {
      -webkit-transform: rotateZ(-28deg);
    }

    13% {
      -webkit-transform: rotateZ(26deg);
    }

    15% {
      -webkit-transform: rotateZ(-24deg);
    }

    17% {
      -webkit-transform: rotateZ(22deg);
    }

    19% {
      -webkit-transform: rotateZ(-20deg);
    }

    21% {
      -webkit-transform: rotateZ(18deg);
    }

    23% {
      -webkit-transform: rotateZ(-16deg);
    }

    25% {
      -webkit-transform: rotateZ(14deg);
    }

    27% {
      -webkit-transform: rotateZ(-12deg);
    }

    29% {
      -webkit-transform: rotateZ(10deg);
    }

    31% {
      -webkit-transform: rotateZ(-8deg);
    }

    33% {
      -webkit-transform: rotateZ(6deg);
    }

    35% {
      -webkit-transform: rotateZ(-4deg);
    }

    37% {
      -webkit-transform: rotateZ(2deg);
    }

    39% {
      -webkit-transform: rotateZ(-1deg);
    }

    41% {
      -webkit-transform: rotateZ(1deg);
    }

    43% {
      -webkit-transform: rotateZ(0);
    }

    100% {
      -webkit-transform: rotateZ(0);
    }
  }

  @-moz-keyframes ring {
    0% {
      -moz-transform: rotate(0);
    }

    1% {
      -moz-transform: rotate(30deg);
    }

    3% {
      -moz-transform: rotate(-28deg);
    }

    5% {
      -moz-transform: rotate(34deg);
    }

    7% {
      -moz-transform: rotate(-32deg);
    }

    9% {
      -moz-transform: rotate(30deg);
    }

    11% {
      -moz-transform: rotate(-28deg);
    }

    13% {
      -moz-transform: rotate(26deg);
    }

    15% {
      -moz-transform: rotate(-24deg);
    }

    17% {
      -moz-transform: rotate(22deg);
    }

    19% {
      -moz-transform: rotate(-20deg);
    }

    21% {
      -moz-transform: rotate(18deg);
    }

    23% {
      -moz-transform: rotate(-16deg);
    }

    25% {
      -moz-transform: rotate(14deg);
    }

    27% {
      -moz-transform: rotate(-12deg);
    }

    29% {
      -moz-transform: rotate(10deg);
    }

    31% {
      -moz-transform: rotate(-8deg);
    }

    33% {
      -moz-transform: rotate(6deg);
    }

    35% {
      -moz-transform: rotate(-4deg);
    }

    37% {
      -moz-transform: rotate(2deg);
    }

    39% {
      -moz-transform: rotate(-1deg);
    }

    41% {
      -moz-transform: rotate(1deg);
    }

    43% {
      -moz-transform: rotate(0);
    }

    100% {
      -moz-transform: rotate(0);
    }
  }

  @keyframes ring {
    0% {
      transform: rotate(0);
    }

    1% {
      transform: rotate(30deg);
    }

    3% {
      transform: rotate(-28deg);
    }

    5% {
      transform: rotate(34deg);
    }

    7% {
      transform: rotate(-32deg);
    }

    9% {
      transform: rotate(30deg);
    }

    11% {
      transform: rotate(-28deg);
    }

    13% {
      transform: rotate(26deg);
    }

    15% {
      transform: rotate(-24deg);
    }

    17% {
      transform: rotate(22deg);
    }

    19% {
      transform: rotate(-20deg);
    }

    21% {
      transform: rotate(18deg);
    }

    23% {
      transform: rotate(-16deg);
    }

    25% {
      transform: rotate(14deg);
    }

    27% {
      transform: rotate(-12deg);
    }

    29% {
      transform: rotate(10deg);
    }

    31% {
      transform: rotate(-8deg);
    }

    33% {
      transform: rotate(6deg);
    }

    35% {
      transform: rotate(-4deg);
    }

    37% {
      transform: rotate(2deg);
    }

    39% {
      transform: rotate(-1deg);
    }

    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
    }
  }
  @-moz-keyframes shake {

    10%,
    90% {
      -moz-transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -moz-transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -moz-transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -moz-transform: translate3d(4px, 0, 0);
    }
  }

  .btn-orange {
    color: #fff;
    background-color: $orange-color;
    border-color: $orange-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $orange-color;
      color: #fff;
    }
  }

  .btn-gray-o {
    color: $primary-text-color;
    background-color: #fff;
    border-color: $primary-text-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $primary-text-color;
      border-color: $primary-text-color;
      color: #fff;
    }
  }
 
 .btn-gray {
    color: #fff;
    background-color: $primary-text-color;
    border-color: $primary-text-color;

    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      border-color: $primary-text-color;
      color: $primary-text-color;
    }
  }

  .btn-edit {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    border: none;
    color: $yellow-color;
    font-size: 16px;
    border-radius: 50%;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: $yellow-color;
      color: #fff;
    }
  }

  .btn-download {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    border: none;
    color: $green-color;
    font-size: 16px;
    border-radius: 50%;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: $green-color;
      color: #fff;
    }
  }

  .btn-plus {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0;
    border: none;
    color: $primary-color;
    font-size: 16px;
    border-radius: 50%;
    background-color: #fff;

    &:hover,
    &:focus {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .btn-del {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    line-height: 30px;
    color: $orange-color;
    font-size: 16px;
    border-radius: 50%;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: $orange-color;
      color: #fff;
    }
  }

  .btn-rounded {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 28px;
    padding: 0;
    text-align: center;
    font-size: 15px;

    i {
      cursor: pointer;
    }
  }

  .close {

    &:hover,
    &:focus {
      outline: none;
    }
  }

  .btn-addNew {
    position: absolute;
    right: 16px;
    top: 0;
  }

  .btn-fileUpload {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      opacity: 0;
      cursor: pointer;
      left: 0;
      top: 0;
    }
  }

  .btn-log {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 23px;
    color: $orange-color;
    border-radius: 50%;
    border: none;
    margin: 0 0 0 1rem;
    background: linear-gradient(135deg, #c4c4c4 0%, #fcfcfc 60%, #ffffff 100%);
    box-shadow: 1px 1px 2px #f5f5f5, -6px -6px 19px #ffffff;
    transition: all .3s ease-in;

    i {
      transform: rotate(90deg);
      transition: all .3s ease-in;
    }

    &:hover {
      background: linear-gradient(145deg, #ffffff, #e1e1e1);
      box-shadow: 5px 5px 6px #dcdcdc,
        -5px -5px 6px #ffffff;

      i {
        transform: rotate(180deg);
      }
    }
  }
.tab-btns{
  .btn-tab{
    width: 80px;
    border-radius: 0;
    &:first-child{
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
    &:last-child{
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}
  .buttons-page-length {
    position: relative;
    border-radius: 2px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px 0 16px;
    line-height: 36px;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #ddd;
    &::after{
      content: '';
      position: absolute;
      right: 2px;
      top: 10px;
      width: 15px;
      height: 15px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='15' height='15'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(0,0,0,1)'/%3E%3C/svg%3E");
    }
    &:focus,&:hover{
      outline: none;
      box-shadow: none;
      border-color: $primary-text-color;
    }
  }
  .dt-button-collection {
    position: absolute;
    background-color: #fff;
    z-index: 500;
    padding: 8px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
    margin-top: -0.5px !important;
    width: 100px;
    div{
      display: flex;
      display: -moz-flex;
      display: -webkit-flex;
      flex-direction: column;
      button{
        border: 0;
        box-shadow: none;
        outline: none;
        text-align: left;
        line-height: 30px;
        background-color: #fff;
        &:hover{
          color: $primary-color;
        }
      }
    }
  }
  .dt-button-background{
    display: none!important;
  }
  .cdk-overlay-container {
    z-index: 1100;
  }

  .scroll-y::-webkit-scrollbar {
    width: 11px;
  }

  .scroll-y {
    scrollbar-width: thin;
    scrollbar-color: rgba($color: $primary-text-color, $alpha: 0.4) $dark-bg-color;
  }

  .scroll-y::-webkit-scrollbar-track {
    background: $dark-bg-color;
  }

  .scroll-y::-webkit-scrollbar-thumb {
    background-color: rgba($color: $primary-text-color, $alpha: 0.4);
    border-radius: 6px;
    border: 3px solid $dark-bg-color;
  }

  .scroll-x,
  .table-responsive {
    scrollbar-width: thin;
    scrollbar-color: rgba($color: $primary-text-color, $alpha: 0.4) $dark-bg-color;
  }

  .scroll-x::-webkit-scrollbar-track,
  .table-responsive::-webkit-scrollbar-track {
    background: $dark-bg-color;
  }

  .scroll-x::-webkit-scrollbar-thumb,
  .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba($color: $primary-text-color, $alpha: 0.4);
    border-radius: 6px;
    border: 3px solid $dark-bg-color;
  }
  .animation-spin{
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* rightsheet */
  .rightSheet {
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    bottom: 0;
    overflow: hidden;
    z-index: 1001;
    width: 0px;
    transition: ease-in 0.8s;
    display: flex;
    justify-content: flex-end;

    .sheetContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      height: 100vh;
      max-width: 30vw;

      .sheetBody {
        width: 100%;
        background-color: #fff;
        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 2s;
        z-index: 1205;
        flex: 1 1 auto;
      }

      .sheetHeader {
        width: 100%;
        margin: 0;
        padding: 20px 16px;
        background-color: #fff;
        border-bottom: 1px solid $border-grey-color;
        flex-shrink: 0;
        z-index: 1205;

        .title-2 {
          position: relative;
          color: $primary-color;
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          text-align: center;
        }

        .btn-close {
          width: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0;
          text-align: center;
          border-radius: 15px;
          position: absolute;
          right: 16px;
          top: 14px;
          background-color: #333;
          color: #fff;
          border: none;
          opacity: 0.4;
          z-index: 10;
        }
      }

      .btn-close:hover,
      .btn-close:focus {
        box-shadow: none;
        outline: none;
        background-color: #fff;
        color: #333;
        opacity: 1;
      }

      .sheetFooter {
        width: 100%;
        padding: 10px 16px;
        z-index: 1205;
        background-color: #fff;
        border-top: 1px solid $border-grey-color;
        text-align: center;
        flex-shrink: 0;
      }

      .internalSheetFooter {
        padding: 1rem 1rem 4rem;
      }
    }


    .sheet-backdrop {
      display: none;
      background-color: #000;
      opacity: 0;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: fixed;
      z-index: 0;
      transition: ease-in-out .6s;
      transition-delay: .8s;
    }
  }

  .rightSheet.active {
    width: 100%;

    .sheetContent {
      position: relative;
      width: 100%;
      background-color: #fff;
    }

    .internalSheetFooter{
      .sheetFooter{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        padding: 10px 16px;
        border-top: 1px solid $border-grey-color;
        z-index: 10;
      }
    }

    .sheet-backdrop {
      display: block;
      -webkit-animation: fadeinout 4s linear forwards;
      animation: fadeinout 4s linear forwards;
    }
  }

  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.2;
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.2;
    }
  }

  .shipment-sheet {
    .sheet-backdrop {
      background-color: transparent;
      background-image: url(/assets/shipment-screenshot.jpg);
      background-size: 100%;
    }
  }

  .shipment-sheet.active {
    .sheet-backdrop {
      background-color: #000;
      -webkit-animation: fadeOut 0.2s linear forwards;
      animation: fadeOut 0.2s linear forwards;
      // animation: none;
    }

  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }

  /* bottomsheet */


  .bottomSheet {
    position: fixed;
    top: auto;
    right: 0;
    bottom: -100%;
    overflow: hidden;
    z-index: 1001;
    width: 100%;
    max-width: calc(100vw - 60px);
    transition: ease-in 0.8s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .sheetContent {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      height: 90vh;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      .sheetBody {
        width: 100%;
        background-color: #fff;
        padding: 1rem;
        overflow-y: auto;
        overflow-x: hidden;
        transition: all 2s;
        z-index: 1205;
        flex: 1 1 auto;
      }

      .sheetHeader {
        width: 100%;
        margin: 0;
        padding: 20px 16px;
        background-color: #fff;
        border-bottom: 1px solid $border-grey-color;
        flex-shrink: 0;
        z-index: 1205;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        .title-2 {
          position: relative;
          color: $primary-color;
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          padding: 0;
          text-align: center;
        }

        .btn-close {
          width: 30px;
          height: 30px;
          line-height: 30px;
          padding: 0;
          text-align: center;
          border-radius: 15px;
          position: absolute;
          right: 16px;
          top: 14px;
          background-color: #333;
          color: #fff;
          border: none;
          opacity: 0.4;
          z-index: 10;
        }
      }

      .btn-close:hover,
      .btn-close:focus {
        box-shadow: none;
        outline: none;
        background-color: #fff;
        color: #333;
        opacity: 1;
      }

      .sheetFooter {
        width: 100%;
        padding: 10px 16px;
        z-index: 1205;
        background-color: #fff;
        border-top: 1px solid $border-grey-color;
        text-align: center;
        flex-shrink: 0;
      }

      .internalSheetFooter {
        padding: 1rem 1rem 4rem;
      }
    }


    .sheet-backdrop {
      display: none;
      background-color: #000;
      opacity: 0;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: fixed;
      z-index: 0;
      transition: ease-in-out .6s;
      transition-delay: .8s;
    }
  }

  .bottomSheet.active {
    width: 100%;
    height: 100%;
    bottom: 0;

    .sheetContent {
      position: relative;
      width: 100%;
      background-color: #fff;
    }

    .internalSheetFooter{
      .sheetFooter{
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        padding: 10px 16px;
        border-top: 1px solid $border-grey-color;
        z-index: 10;
      }
    }

    .sheet-backdrop {
      display: block;
      -webkit-animation: fadeinout 4s linear forwards;
      animation: fadeinout 4s linear forwards;
    }
  }

  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.2;
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.2;
    }
  }

  /* bottomsheet */




  #contactAdd,
  #contactAddModal,
  #reminderModal {
    .modal-body {
      .sheetFooter {
        position: sticky;
      }
    }
  }
  #itemMasterModal,
  #reminderModal {
    .modal-body {
      .sheetFooter {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
      }
    }
  }

  .ng-dropdown-panel {
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba($color: #000000, $alpha: 0.1);
    padding: 6px;
    left: 0;
    font-size: 14px;

    .ng-option {
      padding: 6px 12px;
    }

    .scroll-host::-webkit-scrollbar {
      width: 11px;
    }

    .scroll-host {
      scrollbar-width: thin;
      scrollbar-color: rgba($color: $primary-text-color, $alpha: 0.4) $dark-bg-color;
    }

    .scroll-host::-webkit-scrollbar-track {
      background: $dark-bg-color;
    }

    .scroll-host::-webkit-scrollbar-thumb {
      background-color: rgba($color: $primary-text-color, $alpha: 0.4);
      border-radius: 6px;
      border: 3px solid $dark-bg-color;
    }
  }

  .modal-dialog {
    .modal-content {
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .1);
      border: 0;
    }
  }

  .border-left-success {
    border-left-color: $green-color !important;
  }

  .border-left-danger {
    border-left-color: $orange-color !important;
  }

  .border-left-3-gray{
    border-left: 3px solid rgba($color: $primary-text-color, $alpha: 1) !important;
  }
  .border-left-3-blue{
    border-left: 3px solid rgba($color: $primary-color, $alpha: 0.4)!important;
  }
  .border-left-3-yellow{
    border-left: 3px solid rgba($color: $yellow-color, $alpha: 0.4)!important;
  }
  .border-left-3-red{
    border-left: 3px solid rgba($color: $red-color, $alpha: 0.4)!important;
  }

  .dropdown-menu {
    padding: 10px 16px;
    border: none;
    box-shadow: 0px 0px 10px 1px rgba($color: #000000, $alpha: 0.1);

    .dropdown-item {
      padding: 6px 0;
      margin-bottom: 6px;
      cursor: pointer;
      color: $primary-text-color;
      font-size: 14px;
      border-bottom: 1px dashed $border-grey-color;

      &:hover {
        background-color: #fff;
        color: $primary-color;
      }

      &:last-child {
        border: 0;
        margin: 0;
      }
    }
  }
}

/* body = common stylesheet */
.login {
  background-color: $dark-bg-color;

  .logo {
    position: absolute;
    width: 10%;
    display: inline-block;
    top: 16px;
    z-index: 10;
  }

  .top-bg {
    width: 500px;
    position: absolute;
    top: 0;
    left: 200px;
    z-index: 0;
  }

  .bottom-bg {
    width: 500px;
    position: absolute;
    bottom: 0;
    right: 100px;
    z-index: 0;
  }

  .ship-bg {
    width: 300px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  .collaboration-bg {
    width: auto;
    position: absolute;
    top: 10vh;
    height: 80vh;
    right: 30px;
    z-index: 0;
  }

  .card {
    border: none;
    box-shadow: 0px 0px 30px 1px rgba($color: #000000, $alpha: 0.1);
    padding: 30px;
    border-radius: 6px 42px 6px 6px;
    background-image: url('/assets/login-card-bg.png');
    background-size: 85%;
    background-position: top right;
    background-repeat: no-repeat;

    h2 {
      font-size: 20px;
      padding: 0;
      margin: 0 0 30px;
      text-align: center;
      font-weight: 800;
      color: #022b5c;
    }

    .form-group {
      margin-bottom: 0.1rem;

      label:not(.custom-control-label) {
        position: relative;
        display: inline-block;
        padding: 2px 4px;
        background-color: #fff;
        transform: translateY(16px) translateX(9px);
        z-index: 1;
        opacity: 1;
        color: rgba($color: $primary-text-color, $alpha: 0.8);
      }
      .custom-control-label{
        text-transform: initial;
      }
    }

    .form-control {
      background-color: #fff;
      border-color: rgba($color: $primary-text-color, $alpha: 0.4);
      font-size: 16px;
      color: $primary-text-color;
      font-weight: 600;

      &:focus {
        border-color: #022b5c;
      }
    }
    .btn-primary{
      line-height: 40px;
    }

    .btn-apple {
      background-color: $primary-text-color;
      color: #fff;
    }

    .btn-microsoft {
      background-color: $primary-color;
      color: #fff;
    }

    .btn-google {
      background-color: $orange-color;
      color: #fff;
    }

    .custom-control {
      .custom-control-label {
        font-size: 16px;
      }

      .custom-control-input:checked~.custom-control-label {
        color: $primary-color;
      }
    }
  }

  /* card */

  .tr-data {
    h3 {
      font-size: 30px;
      font-weight: 700;
      color: #022b5c;
      padding: 0;
      margin: 0 0 16px;
    }

    .col-auto {
      font-size: 16px;
      color: $primary-text-color;
      line-height: 1;

      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #022b5c;
        background-color: rgba($color: $primary-color, $alpha: 0.1);
        text-align: center;
        margin: 0 5px 0 0;
        vertical-align: middle;
        border-radius: 50%;
      }
    }

    .coll-img {
      display: block;
      width: 80%;
      margin: 16px 0 0;
    }
  }

  /* card */
}

/* login */
.main {
  .btn-nav-toggle {
    position: fixed;
    top: 32px;
    left: 182px;
    background-color: #fff;
    color: $primary-text-color;
    z-index: 1101;
    font-size: 20px;
    transition: all .3s ease-in;
    box-shadow: 0px 0px 6px 1px rgba($color: #000000, $alpha: 0.1);

    &:hover,
    &:focus {
      box-shadow: 0px 0px 6px 1px rgba($color: #000000, $alpha: 0.1);
    }
  }

  .sidebar {
    width: 200px;
    text-align: center;
    padding: 16px 0;
    position: fixed;
    top: 45px;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: hidden;
    transition: all .4s ease-in-out;
    background-color: #fff;
    box-shadow: 1px 3px 3px 1px rgba($color: #000000, $alpha: 0.1);

    .logo {
      width: 46px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      border-radius: 50%;
      margin: 0 auto 16px;
      background: linear-gradient(145deg, #ffffff, #f3f3f3);
      box-shadow: 2px 2px 6px #c8c8c8,
        -8px -8px 16px #ffffff;

      img {
        width: 36px;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      height: calc(100vh - 176px);
      overflow: hidden;

      &:hover {
        overflow-y: auto;
      }

      li {
        position: relative;
        padding: 0;
        margin: 10px;
        text-align: left;

        a {
          display: block;
          cursor: pointer;
          width: 100%;
          padding: 4px 4px 4px 0;
          font-size: 14px;
          font-weight: 700;
          color: $primary-text-color;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-radius: 6px;

          &:hover {
            color: $primary-color;
            background: linear-gradient(135deg, #c4c4c4 0%, #fcfcfc 60%, #ffffff 100%);
            box-shadow: 4px 4px 7px #c8c8c8, -4px -4px 4px #ffffff;

            img:first-child {
              display: none;
            }

            img:last-child {
              display: inline-block
            }
          }

          img {
            width: 40px;
            vertical-align: middle;
          }

          img:last-child {
            display: none;
          }
        }

        a.active {
          color: $primary-color;
          background: linear-gradient(135deg, #c4c4c4 0%, #fcfcfc 60%, #ffffff 100%);
          box-shadow: 4px 4px 7px #c8c8c8, -4px -4px 4px #ffffff;

          img:first-child {
            display: none;
          }

          img:last-child {
            display: inline-block
          }
        }

        .dropdown {
          height: auto;
          padding: 0 0 0 10px;

          li {
            padding: 0 0 0 16px;
            margin: 10px 0;
            position: relative;

            &::after {
              content: "\EA6E";
              display: block;
              width: 15px;
              height: 15px;
              position: absolute;
              left: 0;
              top: calc(50% - 11px);
              font-family: $remix-font;
            }

            a {
              padding: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight: 400;

              &:hover {
                color: $primary-color;
                background: none;
                box-shadow: none;
              }
            }
          }
        }
      }

      li[aria-expanded='true'] {
        a {
          background: linear-gradient(135deg, #c4c4c4 0%, #fcfcfc 60%, #ffffff 100%);
          box-shadow: 4px 4px 7px #c8c8c8,
            -4px -4px 4px #ffffff;

          img:first-child {
            display: none;
          }

          img:last-child {
            display: inline-block
          }
        }

        .dropdown {
          li {
            a {
              color: $primary-text-color;
              background: none;
              box-shadow: none;

              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }

      .shipment-li {
        a {
          background: #ffe95e;
          /* Old browsers */
          background: -moz-linear-gradient(top, #ffe95e 0%, #f9db17 51%, #a79006 100%);
          /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #ffe95e 0%, #f9db17 51%, #a79006 100%);
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #ffe95e 0%, #f9db17 51%, #a79006 100%);
          border-radius: 6px;
          color: $primary-text-color;
        }

        .dropdown {
          a {
            color: $primary-text-color;
            background: none;
          }
        }
      }
    }/* ul */
    .nav-text{
      font-size: 14px;
      color: rgba($color: $primary-text-color, $alpha: 0.6);
      white-space: nowrap;
      margin: 16px 10px;
      overflow: hidden;
      i{
        font-size: 20px;
        width: 40px;
        text-align: center;
        color: $primary-color;
      }
    }
  }/* sidebar */

  .btn-nav-toggle.close-nav {
    left: 54px;
  }

  .sidebar.small-nav {
    width: 60px;

    &:hover {
      width: 200px;
    }
  }

  .main-body.large-main {
    padding-left: 92px;
  }

  .no-backdrop {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
  }

  /* sidebar */
  .main-body {
    width: 100%;
    padding: 45px 16px 0 36px;
    padding-left: 216px;
    transition: all .4s ease-in;

    .main-nav {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      background-color: #fff;
      box-shadow: 0px 1px 3px 1px rgba($color: #000000, $alpha: 0.1);
      z-index: 1001;
      padding: 6px 16px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-size: 14px;
        padding: 0;
        margin: 0;
        flex: 1 0 auto;
        line-height: 30px;

        img {
          max-width: 200px;
          height: 30px;
          object-fit: cover;
          margin-right: 10px;
        }
      }

      .btn {
        line-height: 1;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 6px;
        }
      }

      .btn-help {
        padding: 0 8px;
        font-size: 28px;
        color: $primary-text-color;
      }
      .country-flag{
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
      .notification{
        position: fixed;
        top: 0;
        right: 60px;
        background-color: #fff;
        padding: 10px 0;
        width: 500px;
        border-radius: 10px;
        box-shadow: 0px 10px 30px 10px rgba($color:$primary-text-color, $alpha: 0.1);
        transform: scale(0);
        transform-origin: center top;
        transition: 0.4s all ease-in;
        &::before{
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-bottom: 10px solid #fff;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          position: absolute;
          top: -9px;
          left: calc(50% - 5px);
        }
        h2{
          font-size: 14px;
          color: $primary-color;
          padding: 0;
          line-height: 1;
          margin: 6px 0 6px 16px;
          text-transform: uppercase;
          text-align: center;
        }
        .list-group{
          max-height: 250px;
          overflow-y: auto;
          display: block;
          .list{
            position: relative;
            font-size: 14px;
            line-height: 18px;
            border-bottom: 1px dashed $border-grey-color;
            padding: 10px 16px;
            overflow-x: hidden;
            overflow-y: visible;
            &:last-child{
              border:none;
            }
            &:hover{
              .btns{
                right: 0;
              }
            }
            a{
              color: $primary-color;
              display: block;
              width: 100%;
              cursor: pointer;
            }
            .dt{
              font-size: 12px;
              text-align: right;
              color: $primary-color;
            }
          }
          .read{
            // background-color: $bg-color;
            color: rgba($color: $primary-text-color, $alpha: 0.6);
            .dt{
              color: rgba($color: $primary-color, $alpha: 0.6);
            }
          }
          .btns{
            padding: 5px;
            height: 100%;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            position: absolute;
            right: -100%;
            top: 0;
            transition: all 0.4s ease-in-out;
          }
        }
        .btn-block{
          color: $orange-color;
          padding: 6px 5px;
          border: 0;
          border-radius: 0 0 10px 10px;
          line-height: 1;
          background-color: #fff;
        }
      }
      .notification.active{
        top: 60px;
        transform: scale(1);
        transition: 0.3s all ease-out;
      }
      .reminder{
        position: fixed;
        top: 0;
        right: 70px;
        background-color: #fff;
        padding: 10px 0 0;
        width: 400px;
        border-radius: 10px;
        box-shadow: 0px 10px 30px 10px rgba($color:$primary-text-color, $alpha: 0.1);
        transform: scale(0);
        transform-origin: center top;
        transition: 0.4s all ease-in;
        &::before{
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-bottom: 10px solid #fff;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          position: absolute;
          top: -9px;
          left: calc(50% - 5px);
        }
        .nav-tabs{
          .nav-item{
            flex: 1;
          }
        }
        h2{
          font-size: 14px;
          color: $primary-color;
          padding: 0;
          line-height: 1;
          margin: 6px 0 6px 16px;
          text-transform: uppercase;
          text-align: center;
        }
        .list-group{
          max-height: 250px;
          overflow-y: auto;
          display: block;
          .list{
            position: relative;
            font-size: 14px;
            line-height: 18px;
            border-bottom: 1px dashed $border-grey-color;
            padding: 10px 16px;
            overflow-x: hidden;
            overflow-y: visible;
            &:last-child{
              border:none;
            }
            &:hover{
              .btns{
                right: 0;
              }
            }
            a{
              color: $primary-color;
            }
            .dt{
              font-size: 12px;
              text-align: right;
              color: $primary-color;
            }
          }
          .read{
            // background-color: $bg-color;
            color: rgba($color: $primary-text-color, $alpha: 0.6);
            .dt{
              color: rgba($color: $primary-color, $alpha: 0.6);
            }
          }
          .btns{
            padding: 5px;
            height: 100%;
            vertical-align: middle;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            position: absolute;
            right: -100%;
            top: 0;
            transition: all 0.4s ease-in-out;
          }
        }
        .btn-block{
          color: $orange-color;
          padding: 6px 5px;
          border: 0;
          border-radius: 0 0 10px 10px;
          line-height: 1;
          background-color: #fff;
        }
      }
      .reminder.active{
        top: 60px;
        transform: scale(1);
        transition: 0.3s all ease-out;
      }
    }/* main-nav */
    .top-nav {
      padding: 10px 16px;
      margin: 0 -16px 16px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 43px;
      left: 0;
      right: 0;
      z-index: 990;
      background-color: $bg-color;

      h2 {
        font-size: 16px;
        color: $primary-text-color;
        font-weight: 600;
        margin: 0;
        padding: 0;
        flex: 0 0 auto;

        a {
          text-decoration: none;
          font-weight: 400;
          color: rgba($color: $primary-color, $alpha: 0.8);
          cursor: pointer;

          &:hover {
            color: $primary-text-color;
          }
        }

        .back-btn {
          color: $primary-color;
          font-size: 18px;

          &:hover {
            color: $orange-color;
          }
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 6px;
        }
      }

      .dropdown-toggle {
        &::before {
          display: none;
        }
      }
    }

    /* top-nav */
    .shipment-dt-nav {
      h2 {
        font-weight: 400;
      }
    }
  }

  .status {
    position: relative;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    padding: 15px 0 0;

    .st {
      position: relative;
      flex: 1 1 auto;
      text-align: center;
      padding: 0 6px;
      font-size: 14px;
      color: $primary-text-color;

      &::after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-color: #dce0f7;
        position: absolute;
        left: calc(50% - 7px);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(0,0,0,0.8)'/%3E%3C/svg%3E");
        background-position: center;
        border-radius: 50%;
        top: -21px;
      }
    }

    .progress {
      position: absolute;
      width: 100%;
      left: 0;
      height: 3px;
      border-radius: 1.5px;
      top: 0;
      background-color: #dce0f7;

      .progress-bar {
        background-color: $primary-color;
      }
    }

    .active {
      color: $primary-color;
      font-weight: 700;

      &::after {
        background-color: $primary-color;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='16' height='16'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
      }
    }
  }

  /* status */

  .filter-col {
    width: 215px;
  }

  .filter {
    background-color: #fff;
    padding: 1rem;
    position: sticky;
    top: 108px;
    height: auto;
    max-height: calc(100vh - 108px);
    overflow-y: auto;
    border-top-left-radius: 30px;

    h2 {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 0;
      margin: 0 0 16px;
      color: $primary-text-color;

      &::after {
        content: '';
        display: block;
        width: 100px;
        height: 2px;
        border-radius: 1px;
        background-color: $yellow-color;
        position: absolute;
        bottom: -4px;
        left: calc(50% -  50px);
      }
    }
  }
  /* filters */
  .nav-tabs {
    width: auto;
    border: none;

    .nav-link {
      position: relative;
      border: none;
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: $primary-text-color, $alpha: 0.6);
      padding: 10px 30px;
    }

    .active {
      color: $primary-color;
      background-color: transparent;

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        border-radius: 1.5px;
        background-color: $primary-color;
        position: absolute;
        bottom: 0px;
        left: 0;
      }
    }
    .import-nav{
      &.active{
        color: $yellow-color;
        &::after{
          background-color: $yellow-color;
        }
      }
    }
  }

  .arrow-tabs{
    position: relative;
    margin: 0 0 8px;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    padding: 0 36px;
    box-shadow: 0px 3px 6px 1px rgba($color: #000000, $alpha: 0.1);
    &::after{
      content: '';
      display: block;
      width: calc(100% - 72px);
      height: 2px;
      border-bottom: 2px dashed #e1e3f5;
      position: absolute;
      top: calc(50% - 0.5px);
      left: 36px;
      z-index: 0;
    }
    .nav-link{
      display: inline-block;
      padding: 12px 6px;
      margin: 0 80px 0 0;
      position: relative;
      z-index: 10;
      background-color: #fff;
      font-size: 14px;
      color: rgba($color: $primary-text-color, $alpha: 0.8);
      font-weight: 600;
      border-radius: 30px;
      cursor: pointer;
      &::before{
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-color: #bac0e5;
        border-radius: 50%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z' fill='rgba(77,99,243,1)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: -50px;
        top: 14.5px;
        z-index: 10;
      }
      &:last-child{
        margin: 0;
        &::before{
          display: none;
        }
      }
      &.active{
        padding: 12px 30px;
        background-color: #4d63f3;
        color: #fff;
      }
      &:hover{
        color: $primary-color;
        background-color: #fff;
      }
    }
    .ri-question-fill{
      position: absolute;
      left: 10px;
      top: calc(50% - 7px);
    }
  }

  .nav-tab-v {
    background-color: #fff;

    .nav-item {
      .nav-link {
        padding: 8px 16px;
        border-bottom: 1px solid $border-grey-color;
      }

      &:last-child {
        .nav-link {
          border: none;
        }
      }
    }

    .active {
      &::after {
        content: '';
        display: block;
        width: 3px;
        height: 100%;
        border-radius: 1.5px;
        background-color: $primary-color;
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 15px solid #fff;
        position: absolute;
        top: 0;
        right: -14px;
      }
    }
  }

  .dataTables_wrapper {
    padding: 0;
    margin: 0;
    width: auto;
    .dt-buttons,
    .dataTables_filter {
      display: inline-block;
      width: 50%;
      input[type="search"].form-control{
        min-width: 250px;
      }
    }

    .page-item.active {
      .page-link {
        background-color: $primary-color;
      }
    }
  }
  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc_disabled:before,
  table.dataTable thead .sorting_desc_disabled:after{
    opacity: 0.6;
  }
  div.dataTables_wrapper>div.row:first-child>div[class^="col-"]:last-child {
    text-align: right;
  }
  .dataTables_scrollFootInner ,
  .dataTables_scrollHeadInner{
    width: 100%!important;
  }
    .report-bg-yellow:nth-child(4n + 3),
    .report-bg-yellow:nth-child(4n + 4)
  {
    background-color: rgba($color: $primary-text-color, $alpha: 0.2);
  }

  .scrollable-head-table .dataTables_scrollHead {
    overflow-y: scroll!important;
  }
  .report-table{
    .dataTables_wrapper {
      padding: 0;
      margin: 0;
      width: auto;

      .dt-buttons,
      .dataTables_filter {
        display: block;
        width: 100%;
      }
    }
  }

  .table {
    width: 100% !important;
    background-color: #fff;
    border: 1px solid $border-grey-color;
    border-collapse: collapse !important;

    thead {
      th {
        background-color: $bg-color;
        font-weight: 400;
        color: rgba($color: $primary-text-color, $alpha: 0.8);
        border-bottom-width: 1px;
      }
    }

    tbody {
      td {
        font-weight: 400;
        color: $primary-text-color;
        vertical-align: middle;

        a:not(.dropdown-item) {
          color: $primary-color;
        }
        .dropdown-menu {
          min-width: 200px;
          .dropdown-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .dropdown-toggle {
          position: relative;
      
          &::before {
            display: none;
          }
        }
        .btn-link {
          line-height: 16px;
        }
      }
      tr.text-danger {
        td {
          color: $red-color;
        }
      }
    }

  }
  .report-table{
    th{
      white-space: nowrap;
    }
  }
  .DTFC_LeftHeadWrapper{
    .table{
      margin-bottom: 0!important;
      border-bottom: 0;
    }
  }
  .dataTables_scrollHeadInner{
    width: 100%!important;
  }
  .DTFC_LeftBodyLiner{
    .table{
      margin: 0!important;
      tbody{
        tr{
          &:first-child{
            td{
              border-top: 0;
            }
          }
          .exp-td{
            position: relative;
            &::before{
              content: '';
              display: block;
              width: 3px;
              height: 100%;
              background-color: $yellow-color;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .imp-td{
           position: relative;

           &::before {
             content: '';
             display: block;
             width: 3px;
             height: 100%;
             background-color: $primary-color;
             position: absolute;
             left: 0;
             top: 0;
           }
          }
        }
      }
    }
  }
  .DTFC_RightHeadWrapper{
    .table{
      margin: 6px 0 0!important;
    }
  }
  .DTFC_RightBodyWrapper{
    .table{
      margin: 0!important;
    }
  }
  /* table */
  .image-radio {
    position: relative;

    input {
      position: absolute;
      opacity: 0;
      top: 0;
    }

    label {
      position: relative;
      display: block;
      margin: 0;
      cursor: pointer;
      border: 1px solid $border-grey-color;
      padding: 10px;
    }

    input:checked~label::after {
      content: "\EB79";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: $orange-color;
      font-family: $remix-font;
    }

    input:checked~label {
      border-color: $orange-color;
    }

    input:checked~label img {
      opacity: .6;
    }
  }

  .invoice-add {
    .card {
      font-family: 'Nunito', sans-serif;
      max-height: calc(100vh - 120px);
      overflow-y: auto;
      position: sticky;
      top: 58px;

      .logo {
        max-width: 100px;
        max-height: 100px;
      }

      .custom-select,
      .form-control:not(textarea) {
        height: 36px;
        line-height: 1;
        padding: 0.2rem 0.75rem;
      }
      .form-control{
        min-height: 36px;
      }

      .form-control,
      .custom-select {
        border: 0;
        background-color: #f2f2f5;

        &:focus {
          background-color: #fff;
          outline: 1px solid $primary-color;
        }
      }

      .input-group-text {
        background-color: #f2f2f5;
        height: 36px;
        border: 0;
      }

      h2 {
        background-color: $dark-bg-color;
        color: $primary-color;
        font-size: 16px;
        padding: 10px 16px;
        margin: 16px -16px;
      }

      h2[data-toggle='collapse'] {
        position: relative;

        &::after {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(77,99,243,1)'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          right: 0;
          top: 10px;
          transition: all .4s ease-in;
        }
      }

      h2[aria-expanded='true'] {
        &::after {
          transform: rotate(180deg);
        }
      }

      p {
        margin: 0;
      }

      .alert-warning {
        margin: -16px -16px 16px;
      }
    }

    /* card */

    .btns {
      position: sticky;
      margin-left: -16px;
      margin-right: -16px;
      bottom: -16px;
      padding: 6px 10px;
      background-color: #fff;
      text-align: center;
      box-shadow: 0px -3px 3px 1px rgba($color: #000000, $alpha: 0.1);
      .dropdown-toggle {
        &::before {
          display: none;
        }
      }
    }

    .table {
      .btn-del {
        position: absolute;
        right: 3px;
      }
    }
  }

  /* invoice-add */

  .invoice-add-half {
    .card {
      height: 50vh;
    }
  }

  .print-wrapper {
    height: calc(50vh - 120px);
    position: relative;
    overflow: hidden auto;

    .btns {
      position: sticky;
      left: 0;
      right: 0;
      width: 100%;
      bottom: 0;
      padding: 6px 10px;
      background-color: #fff;
      text-align: center;
      box-shadow: 0px -3px 3px 1px rgba($color: #000000, $alpha: 0.1);
      .dropdown-toggle {
        &::before {
          display: none;
        }
      }
    }

    .innerHTMLCode {
      min-height: 100%;
    }

    .ribbon {
      position: absolute;
      width: auto;
      padding: 4px 16px 4px 23px;
      font-size: 12px;
      background-color: #4D63F3;
      color: #fff;
      text-align: right;
      top: 0;
      right: 0;
      letter-spacing: 0.5px;

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid #fff;
        position: absolute;
        left: 0;
        top: -2px;
      }
    }
  }

  .print {
    position: absolute;
    cursor: move;
    width: 100%;
    // max-width: 1191px;
    font-size: 14px;
    color: $primary-text-color;
    border: 1px solid $border-grey-color;
    height: 100%;

    /* .row {
      margin: 0;
    }

    /* div[class^="col"] {
      padding: 0 6px;
    }

    h1 {
      font-size: 16px;
      color: $primary-text-color;
      padding: 0;
      font-weight: 600;
      margin: 0 0 10px;
    }

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: $primary-text-color;
      padding: 0;
      margin: 0 0 4px;
    }

    p {
      font-size: 14px;
      color: rgba($color: $primary-text-color, $alpha: 0.8);
      margin: 0;

      span {
        color: $primary-text-color;
        display: inline-block;
      }
    }

    label {
      font-size: 14px;
      margin: 0;
      color: rgba($color: $primary-text-color, $alpha: 0.8);
    }

    hr {
      margin: 1rem -10px;
      border-color: $border-grey-color;
    }

    .mx--1 {
      margin: 0 -9px;
    } */
  }

  /* print */
  .existing-print {
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba($color: $primary-text-color, $alpha: 0.4) $dark-bg-color;
  }

  .existing-print::-webkit-scrollbar-track {
    background: $dark-bg-color;
  }

  .existing-print::-webkit-scrollbar-thumb {
    background-color: rgba($color: $primary-text-color, $alpha: 0.4);
    border-radius: 6px;
    border: 3px solid $dark-bg-color;
  }

  /* print */
  .mat-option {
    vertical-align: middle;

    img {
      display: inline-block;
      margin-right: 6px;
    }
  }
  
  .mat-select {
    vertical-align: sub;
  }

  .mat-form-field-appearance-legacy .mat-form-field-infix {
    height: 30px;
    padding: 0;
    margin: 0;
    border: none;
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-select-arrow {
    border: none;
  }

  .mat-select-trigger {
    height: 100%;
  }

  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }

  .mat-slider-horizontal {
    width: 100%;
    min-width: 100%;
  }

  .noUi-horizontal {
    height: 10px;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    background-color: #EFEFEF;

    .noUi-handle {
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      top: -12px;
      right: -15px;
      background-color: $primary-color;
      box-shadow: 0px 0px 10px 2px rgba($color: #000000, $alpha: 0.1);

      &::after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        left: calc(50% - 7.5px);
        top: calc(50% - 7.5px);
      }

      &::before {
        display: none;
      }

      &:hover,
      &:focus {
        outline: none;
      }

      .noUi-tooltip {
        background-color: $primary-color;
        border: none;
        color: #fff;
        font-size: 14px;
        padding: 4px 10px;

        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 8px solid $primary-color;
          border-right: 8px solid transparent;
          border-left: 8px solid transparent;
          position: absolute;
          bottom: -6px;
          left: 12px;
        }
      }
    }
  }

  .noUi-connect {
    background-color: $primary-color;
  }

  .noUi-pips-horizontal {
    height: auto;
    padding: 0;
  }

  .noUi-marker-horizontal.noUi-marker-large {
    height: 10px;
  }

  .noUi-marker-horizontal.noUi-marker {
    width: 1px;
    margin-left: .5px;
  }

  .ng-select .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select {
    .ng-select-container {
      height: 100%;
      align-items: center;

      .ng-placeholder {
        color: #c0c0c0;
      }

      input {
        height: 100%;
      }

      .ng-value-container,
      .ng-value {
        align-items: center;
      }
    }

    .ng-dropdown-panel {
      top: 105%;
    }
  }

  .ng-select.disabled {
    .ng-select-container {
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  #fileDragUploadModal {
    .modal-content {
      height: 90vh;
    }
  }

  #fileAddModal {
    .modal-content {
      .modal-body {
        min-height: 60vh;

        .ng-dropdown-panel {
          .scroll-host {
            height: 100px;
            overflow-y: auto;
          }
        }
      }
    }
  }

  .license {
    .progress {
      height: 6px;
      border-radius: 3px;
    }

    .table {
      tbody {
        td {
          vertical-align: middle;
        }
      }
    }
    .license-done{
      background-color: rgba($color: $primary-text-color, $alpha: 0.2);
      color: rgba($color: $primary-text-color, $alpha: 0.8);
      .progress{
        .progress-bar{
          background-color: rgba($color: $primary-text-color, $alpha: 0.6)!important;
        }
      }
    }
  }

  .licese-details {
    .progress {
      height: 15px;
      border-radius: 7.5px;

      .progress-bar {
        font-size: 12px;
      }
    }

    .import-table {
      thead {
        tr {
          th {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }
    }

    .export-table {
      thead {
        tr {
          th {
            background-color: $yellow-color;
          }
        }
      }
    }

    #exports-tab.active {
      color: $yellow-color;

      &::after {
        background-color: $yellow-color;
      }
    }
  }

.license-card{
  position: absolute;
  top: 104px;
  z-index: 99;
  right: 112px;
  .card {
    border-radius: 12px;
    background-color: $primary-color;
    border: none;
    box-shadow: 0px 0px 20px 2px rgba($color: #000000, $alpha: 0.1);
    padding: 2rem 1rem;
    background-image: url(/assets/license-bg.png);
    background-size: 100%;
    background-position: top center;
    background-repeat: no-repeat;

    .close{
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 14px;
      color: #fff;
    }

    h2 {
      font-size: 16px;
      color: #fff;
      padding: 0;
      font-weight: 700;
      margin: 0 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    }

    .lt-dt {
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      label {
        display: block;
        font-size: 12px;
        font-weight: 600;
        color: rgba($color: #fff, $alpha: 0.6);
        margin: 0 0 2px;
      }
    }
  }
}

  /* details */
  .item-table {
    input {
      min-width: 80px;
    }

    .item-tr {
      td {
        position: relative;
        min-height: 33px;

        .ng-select {
          border: none;
          padding: .2rem .5rem;

          input {
            padding: 0;
          }

          .ng-select-container {

            .ng-value-container,
            .ng-value {
              align-items: center;
            }

            .ng-clear-wrapper {
              line-height: 25px;
            }
          }
        }

        padding: 0;

        select,.custom-select, input {
          border: none;
          border-bottom: 1px dashed #ddd;
          transform: translateY(1px);
          padding: .2rem .5rem !important;

          &:focus {
            outline: none;
            box-shadow: none;
            border-color: $primary-text-color;
          }
        }

      }
    }

    .input-group {
      height: 32px;
      flex-wrap: nowrap;

      .input-group-append {
        height: 32px;

        .input-group-text {
          height: 32px;
          line-height: 32px;
          border: none;
        }
      }
    }

    .desc-w {
      min-width: 250px;
    }
  }

  .mat-accent .mat-slider-track-fill,
  .mat-accent .mat-slider-thumb,
  .mat-accent .mat-slider-thumb-label {
    background-color: $primary-color;
  }

  .mat-slider-horizontal {
    height: 34px;

    .mat-slider-wrapper {
      top: 16px;
    }
  }

  .email-pop-up {
    position: absolute;
    bottom: 68px;
    top: auto;
    height: auto !important;
    max-height: 60vh;
    background-color: #fff;
    width: 300px;
    overflow: hidden;
    transform: scale(0);
    transform-origin: center bottom;
    transition: all .4s ease-in;

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 16px;
    }
  }

  .email-pop-up.active {
    transition: all .4s ease-out;
    transform: scale(1);
    padding: 1rem;
    box-shadow: 0px 0px 10px 1px rgba($color: #000000, $alpha: 0.1);
  }

  .table-overview {
    background-color: #fff;
    border-radius: 4px;
    margin: 0 0 16px;
    padding: 30px 15px 15px;
    font-size: 16px;
    font-weight: 700;
    color: $primary-text-color;
    box-shadow: 0px 2px 10px 1px rgba($color: #000000, $alpha: 0.1);

    label {
      display: block;
      font-size: 14px;
      font-weight: 400;
      margin: 4px 0 0;
    }

    .currency {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      line-height: 40px;
      color: #fff;
      margin: 0 0 16px;
      position: absolute;
      left: calc(50% - 20px);
      top: -42px;
      box-shadow: 0px 10px 20px 4px rgba($color: #000000, $alpha: 0.1);
    }

    .card-col {
      border-right: 1px solid $border-grey-color;
    }

    .card-col:first-child {
      .currency {
        background-color: $yellow-color;
        color: $primary-text-color;
      }
    }

    .card-col:nth-child(2) {
      .currency {
        background-color: $primary-color;
      }
    }

    .card-col:nth-child(3) {
      .currency {
        background-color: $green-color;
      }
    }

    .card-col:last-child {
      border-right: 0;

      .currency {
        background-color: $orange-color;
      }
    }
    &.reports-table-overview{
      overflow-y: auto;
      padding-top: 40px;
      .currency{
        top: -38px;
      }
    }
  }

  /* table-overview */
  .doc-status {
    font-size: 14px;
    .scroll-y{
      height: calc(100vh - 298px);
      overflow: hidden auto;
    }
    ul {
      position: relative;
      list-style: none;
      padding-left: 80px;

      &::after {
        content: '';
        display: block;
        width: 3px;
        height: 100%;
        background-color: $dark-bg-color;
        border-radius: 1.5px;
        position: absolute;
        left: 34px;
        top: 0;
        z-index: 0;
      }

      li {
        position: relative;
        margin-bottom: 30px;
        min-height: 42px;
        color: $primary-text-color;

        &:last-child {
          margin-bottom: 0;
        }

        .comm{
          padding: 4px 0;
          color: rgba($primary-text-color, 0.8);
        }

        .dt {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: absolute;
          left: -70px;
          top: 0;
          line-height: 1;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          color: $primary-text-color;
          background-color: #fff;
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
          padding: 13px 0px;
          z-index: 100;

          small {
            font-weight: 300;
            color: rgba($primary-text-color, 0.9);
            font-size: 12px;
          }
        }

        .st {
          font-size: 12px;
          font-weight: 300;
          color: rgba($color: $primary-text-color, $alpha: 0.8);
        }
      }
    }
  }

  .shipment-select {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          img {
            width: 20px;
          }
        }
      }
    }
  }

  .template-card {
    .card {
      border: 0;
      border-radius: 4px;
      margin: 0 0 1rem;
      padding: 0;
      box-shadow: 0px 0px 10px 1px rgba($color: $primary-color, $alpha: 0.1);

      input {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        opacity: 0;
        cursor: pointer;
      }

      img {
        display: block;
        position: relative;
        width: 100%;
      }

      h4 {
        font-size: 14px;
        font-weight: 700;
        color: $primary-text-color;
        text-align: center;
        margin: 0;
        padding: 10px 6px;
      }

      input:checked~h4 {
        &::after {
          content: "\EB79";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          color: $orange-color;
          font-family: $remix-font;
          background-color: rgba($color: #fff, $alpha: 0.6);
        }
      }
    }
  }

  .payment-modal {
    h6 {
      text-align: center;
      padding: 0.6rem 1rem;
      background-color: $primary-color;
      color: #fff;
    }
  }
  // #orderReconModal{
  //   .table{
  //     thead{
  //       tr{
  //         &:first-child{
  //           th{
  //             &:first-child{
  //               left: 0;
  //               z-index: 10;
  //             }
  //             &:nth-child(2){
  //               position: relative;
  //               left: auto;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     tr{
  //       th{
  //         white-space: nowrap;
  //         &:first-child{
  //           position: sticky;
  //           left: -1px;
  //           width: 89px;
  //         }
  //         &:last-child{
  //           position: sticky;
  //           right: 0;
  //         }
  //         &:nth-child(2) {
  //           position: sticky;
  //           left: 89px;
  //           width: 51px;
  //         }

  //         &:nth-child(3) {
  //           position: sticky;
  //           left: 140px;
  //           width: 58px;
  //         }

  //         &:nth-child(4) {
  //           position: sticky;
  //           left: 198px;
  //           width: 86px;
  //         }

  //         &:nth-child(5) {
  //           position: sticky;
  //           left: 284px;
  //           width: 152px;
  //         }
  //       }
  //       td{
  //         background-color: #fff;
  //         &:first-child{
  //           position: sticky;
  //           left: -1px;
  //           width: 89px;
  //         }
  //         &:last-child{
  //           position: sticky;
  //           right: 0;
  //         }
  //         &:nth-child(2) {
  //           position: sticky;
  //           left: 89px;
  //           width: 51px;
  //         }

  //         &:nth-child(3) {
  //           position: sticky;
  //           left: 140px;
  //           width: 58px;
  //         }

  //         &:nth-child(4) {
  //           position: sticky;
  //           left: 198px;
  //           width: 86px;
  //         }

  //         &:nth-child(5) {
  //           position: sticky;
  //           left: 284px;
  //           width: 152px;
  //         }
  //       }
  //     }
  //   }
  // }
  // /* orderReconModal */
  .buyerAddModal {
    .sheetFooter {
      position: sticky;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      padding: 10px 16px;
      border-top: 1px solid #ddd;
      text-align: center;
      background-color: #fff;
    }
  }
  .form-card{
    border: 0;
    padding: 1rem;
  }
  .checkbox-dropdown{
    line-height: 1;
    width: 14rem;
    .custom-control{
      position: relative;
      padding: 0.8rem 1rem 0.8rem 1.6rem;
      border-bottom: 1px dashed $border-grey-color;
      transition: all .4s ease-in-out;
      cursor: ns-resize;
      background: #fff;
      &:last-child{
        border: 0;
      }
      &::after{
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M18 11V8l4 4-4 4v-3h-5v5h3l-4 4-4-4h3v-5H6v3l-4-4 4-4v3h5V6H8l4-4 4 4h-3v5z' fill='rgba(199,204,241,1)'/%3E%3C/svg%3E");
        position: absolute;
        right: 0;
        top: calc(50% - 7px);
      }
      .custom-control-label{
        cursor: ns-resize;
      }
    }    
    .cdk-drag-placeholder {
      opacity: 0;
    }
  }
  .signImg{
    width: 100%;
    max-width: 200px;
  }
  .msg-container {
    font-size: 14px;
    font-weight: 400;
    color: $primary-text-color;
    white-space: initial;
    font-family: inherit;
    margin: 0 0 10px;
    overflow: hidden;
    word-break: break-all;
    width: auto;
    max-width: 100%;
    p {
      margin: 0;
    }
    ul {
      li {
        list-style: disc!important;
      }
    }
    table {
      th {
        font-weight: 600;
        padding: 6px 12px;
      }
      td {
        padding: 6px 12px;
      }
    }
  }

}
/* main */
.mat-pseudo-checkbox {
  border: #adb5bd solid 1px !important;
  border-radius: 0.25rem !important;
}

.mat-pseudo-checkbox-checked::after {
  top: 3.4px !important;
  left: 3px !important;
  width: 6px !important;
  height: 3px !important;
  border-left: 2px solid currentColor !important;
  transform: rotate(-45deg) !important;
  opacity: 1 !important;
  box-sizing: content-box !important;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: $primary-color;
}

.mat-option.mat-active,
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background-color: #fff;
}

.ng-select-multiple {
  .ng-select-container {
    .ng-value-container {
      .ng-value {
        font-size: 14px;
        padding: 4px 6px;
        background-color: $dark-bg-color;
        margin-right: 4px;
        border-radius: 10px;
      }

      .ng-value-icon {
        margin-right: 4px;
      }
    }
  }
}
.reminder-modal-body{
  .ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          margin: 4px;
        }
      }
    }
  }
}
.mat-tooltip {
  margin: 4px;
  background: rgba($color:$primary-text-color, $alpha: 1);
  opacity: 1;
  font-size: 12px;
}

.ext-user {
  .main-body{
    padding: 45px 16px 0 16px;
  }
  .top-nav {
    top: 0px !important;
  }

  .chat {
    height: calc(100vh - 170px) !important;
  }

  .parent-flex {
    height: calc(100vh - 154px) !important;

    .ship-space {
      max-height: calc(100vh - 130px) !important;
    }
  }
}

.toggler {
  margin-bottom: 15px;

  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
    vertical-align: middle;
    margin-bottom: 0 !important;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dee2e6;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

.toggler span:not(.slider) {
  display: inline-block;
  margin: 0 5px;
  vertical-align: middle;
  font-weight: 500;
}

.toggler input:checked+.slider {
  background-color: $primary-color;
}

.toggler input:focus+.slider {
  box-shadow: none;
}

.toggler input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.toggler .slider.round {
  border-radius: 34px;
}

.toggler .slider.round:before {
  border-radius: 50%;
}
.item-dropdown{
  .ng-dropdown-panel{
    width: calc(100% + 200px);
    .ng-dropdown-panel-items {
      .ng-option{
        white-space: initial;
        text-overflow: unset;
        border-bottom: 0.5px dashed $border-grey-color;
      }
    }
  }
}
mat-optgroup.mat-optgroup{
  display: inline-block;
  width: 49.5%;
  vertical-align: top;
}
.mat-select-panel .mat-optgroup-label {
  line-height: 14px;
  margin: 8px 0;
  height: auto;
}
.mat-select-panel .mat-optgroup-label {
    height: 1em!important;
    line-height: 1em!important;
}
.mat-option-text {
  display: block;
  line-height: 1;

  small {
    display: block;
    line-height: 1;
    height: auto;
    margin: 4px 0 0;
  }
}

.wide-bottom-sheet {
  width: 100%;
  max-width: calc(100vw) !important;
  min-width: 100vw !important;
}
editor{
  height: 100%;
  .tox-tinymce{
    height: 100%!important;
    border: 0!important;
  }
  .tox-toolbar__primary{
    background-image: none!important;
    border: 1px solid  $border-grey-color!important;
  }
  .tox-statusbar{
    display: none!important;
  }
}
.select-user-dropdown {
  .mat-select-value-text {
    color: $primary-color;
  }
}

/* ----------------media query---------------------- */
@media only screen and (min-width: 1200px) and (max-width: 1366px){
  .dash-ct{
    .card{
      .ct{
        font-size: 14px!important;
      }
      label{
        font-size: 12px!important;
      }
    }
  }
}